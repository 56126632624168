// import React, { useState } from "react";
// import AWS from "aws-sdk";
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   AppBar,
//   Toolbar,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import greenBgImage from "../../../assets/images/bg-profile.jpeg";
// import CoverLayout from "../components/CoverLayout";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

// const StyledTextField = styled(TextField)({
//   "& .MuiInputBase-root": {
//     color: "black", // Text color
//   },
//   "& .MuiInputLabel-root": {
//     color: "black", // Label color
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "black", // Border color
//     },
//     "&:hover fieldset": {
//       borderColor: "black",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "black",
//     },
//   },
// });

// const Login = () => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [session, setSession] = useState(null);
//   const [showOtpForm, setShowOtpForm] = useState(false);
//   const [resultMessage, setResultMessage] = useState("");
//   const [value, setValue] = useState();

//   AWS.config.region = "us-west-1";
//   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: "us-west-1:eefb5c8c-ce2-4af5-94d6-8dc1f66edd58",
//   });

//   const cognitoIdentityServiceProvider =
//     new AWS.CognitoIdentityServiceProvider();

//   const sendOTP = (phoneNumber) => {
//     const params = {
//       ClientId: config.clientId,
//       AuthFlow: "CUSTOM_AUTH",
//       AuthParameters: {
//         USERNAME: phoneNumber,
//       },
//     };

//     cognitoIdentityServiceProvider.initiateAuth(params, (err, data) => {
//       if (err) {
//         console.log("Initiate Auth Error:", err);
//         if (err.code === "UserNotConfirmedException") {
//           console.log("Sending OTP...");
//         }
//       } else {
//         if (data.ChallengeName === "CUSTOM_CHALLENGE") {
//           setSession(data.Session);
//           setShowOtpForm(true);
//         } else {
//           console.log("Unexpected Challenge Name:", data.ChallengeName);
//         }
//       }
//     });
//   };

//   const handleSignupSubmit = (event) => {
//     event.preventDefault();
//     const params = {
//       ClientId: config.clientId,
//       Username: phoneNumber,
//       Password: "dummyPassword",
//       UserAttributes: [
//         {
//           Name: "phone_number",
//           Value: phoneNumber,
//         },
//       ],
//     };

//     cognitoIdentityServiceProvider.signUp(params, (err, data) => {
//       if (err) {
//         if (err.code === "UsernameExistsException") {
//           console.log("User already exists");
//           sendOTP(phoneNumber);
//         } else {
//           console.log("Sign Up Error:", err);
//         }
//       } else {
//         sendOTP(phoneNumber);
//       }
//     });
//   };

//   const handleOtpSubmit = (event) => {
//     event.preventDefault();
//     const params = {
//       ChallengeName: "CUSTOM_CHALLENGE",
//       ClientId: config.clientId,
//       ChallengeResponses: {
//         USERNAME: phoneNumber,
//         ANSWER: otp,
//       },
//       Session: session,
//     };

//     cognitoIdentityServiceProvider.respondToAuthChallenge(
//       params,
//       (err, data) => {
//         if (err) {
//           console.log("Respond to Auth Challenge Error:", err);
//           setResultMessage("Invalid OTP");
//         } else {
//           if (data.AuthenticationResult) {
//             console.log("Login Successful");
//             sessionStorage.setItem("phoneNumber", phoneNumber); // Store phone number in session storage
//             window.location.href =
//               "/Upload?phone=" + encodeURIComponent(phoneNumber); // Redirect to the Upload page with the phone number
//           } else {
//             setResultMessage("Invalid OTP");
//           }
//         }
//       }
//     );
//   };

//   return (
//     <CoverLayout>
//       <Container maxWidth="sm">
//         <Box my={4}>
//           <Typography variant="h4" component="h1" gutterBottom>
//             User Login
//           </Typography>
//           {!showOtpForm ? (
//             <form
//               onSubmit={handleSignupSubmit}
//               style={{ width: "100%", textAlign: "center" }}
//             >
//               <PhoneInput
//                 defaultCountry="IN"
//                 placeholder="Enter phone number"
//                 value={value}
//                 onChange={setValue}
                
//               />
//               <StyledTextField
//                 fullWidth
//                 type="tel"
//                 id="phoneInput"
//                 label="Enter Phone Number with +91"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 margin="normal"
//                 variant="outlined"
//               />
//               <Button
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//                 style={{ marginTop: "1rem" }}
//               >
//                 Get OTP
//               </Button>
//             </form>
//           ) : (
//             <form
//               onSubmit={handleOtpSubmit}
//               style={{ width: "100%", textAlign: "center" }}
//             >
//               <StyledTextField
//                 fullWidth
//                 type="text"
//                 id="otpInput"
//                 label="OTP"
//                 required
//                 value={otp}
//                 onChange={(e) => setOtp(e.target.value)}
//                 margin="normal"
//                 variant="outlined"
//               />
//               <Button
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//                 style={{ marginTop: "1rem" }}
//               >
//                 Verify OTP
//               </Button>
//             </form>
//           )}
//           <Typography variant="body1" color="error" align="center" mt={2}>
//             {resultMessage}
//           </Typography>
//         </Box>
//       </Container>
//     </CoverLayout>
//   );
// };

// export default Login;

import React, { useState } from "react";
import AWS from "aws-sdk";
import {
  Container,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import greenBgImage from "../../../assets/images/forestbg2.jpeg";
import CoverLayout from "../components/CoverLayout";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MDTypography from "components/MDTypography";
import "./login.css";
import config from "./config";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [session, setSession] = useState(null);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [value, setValue] = useState();


  AWS.config.region = "ap-south-1";
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IdentityPoolId,
  });

  const cognitoIdentityServiceProvider =
    new AWS.CognitoIdentityServiceProvider();

  const sendOTP = (phoneNumber) => {
    const params = {
      ClientId: config.clientId,
      AuthFlow: "CUSTOM_AUTH",
      AuthParameters: {
        USERNAME: phoneNumber,
      },
    };

    cognitoIdentityServiceProvider.initiateAuth(params, (err, data) => {
      if (err) {
        console.log("Initiate Auth Error:", err);
        if (err.code === "UserNotConfirmedException") {
          console.log("Sending OTP...");
        }
      } else {
        if (data.ChallengeName === "CUSTOM_CHALLENGE") {
          setSession(data.Session);
          setShowOtpForm(true);
        } else {
          console.log("Unexpected Challenge Name:", data.ChallengeName);
        }
      }
    });
  };

  const handleSignupSubmit = (event) => {
    event.preventDefault();
    const params = {
      ClientId: config.clientId,
      Username: phoneNumber,
      Password: "dummyPassword",
      UserAttributes: [
        {
          Name: "phone_number",
          Value: phoneNumber,
        },
      ],
    };

    cognitoIdentityServiceProvider.signUp(params, (err, data) => {
      if (err) {
        if (err.code === "UsernameExistsException") {
          console.log("User already exists");
          sendOTP(phoneNumber);
        } else {
          console.log("Sign Up Error:", err);
        }
      } else {
        sendOTP(phoneNumber);
      }
    });
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    const params = {
      ChallengeName: "CUSTOM_CHALLENGE",
      ClientId: config.clientId,
      ChallengeResponses: {
        USERNAME: phoneNumber,
        ANSWER: otp,
      },
      Session: session,
    };

    cognitoIdentityServiceProvider.respondToAuthChallenge(
      params,
      (err, data) => {
        if (err) {
          console.log("Respond to Auth Challenge Error:", err);
          setResultMessage("Invalid OTP");
        } else {
          if (data.AuthenticationResult) {
            console.log("Login Successful");
            sessionStorage.setItem("phoneNumber", phoneNumber); // Store phone number in session storage
            window.location.href =
              "/Upload?phone=" + encodeURIComponent(phoneNumber); // Redirect to the Upload page with the phone number
          } else {
            setResultMessage("Invalid OTP");
          }
        }
      }
    );
  };
  return (
    <CoverLayout image={greenBgImage}>

    <div className="title-container">
      <MDTypography variant="h1" mb={2} textAlign="center" color="white">
        Ecoscore
      </MDTypography>
    </div>
    <Container maxWidth="sm">
      <Box my={4} className="transparent-box">
        <MDTypography variant="h4" component="h1" gutterBottom color="white" textAlign="center">
          User Login
        </MDTypography>
        {!showOtpForm ? (
          <form onSubmit={handleSignupSubmit} className="form-container">
            <PhoneInput
              defaultCountry="IN"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="phone-input"
              style={{ fontSize: "20px", padding: "14px" }}
            />
            <Button
              variant="contained"
              color="white"
              type="submit"
              className="action-button"
            >
              Get OTP
            </Button>
          </form>
        ) : (
          <form onSubmit={handleOtpSubmit} className="form-container">
            <TextField
  fullWidth
  type="text"
  id="otpInput"
  label="OTP"
  required
  value={otp}
  onChange={(e) => setOtp(e.target.value)}
  margin="normal"
  variant="outlined"
  className="otp-input"
/>

            <Button
              variant="contained"
              color="white"
              type="submit"
              className="action-button"
            >
              Verify OTP
            </Button>
          </form>
        )}
        <Typography variant="body1" color="error" align="center" mt={2}>
          {resultMessage}
        </Typography>
      </Box>
    </Container>
  </CoverLayout>
  
  );
};

export default Login;

// import React, { useState } from "react";
// import AWS from "aws-sdk";
// import {
//   Container,
//   Button,
//   Typography,
//   Box,
//   TextField,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import greenBgImage from "../../../assets/images/forestbg2.jpeg";
// import CoverLayout from "../components/CoverLayout";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import MDTypography from "components/MDTypography";
// import "./login.css";
// import config from "./config";

// const Login = () => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [session, setSession] = useState(null);
//   const [showOtpForm, setShowOtpForm] = useState(false);
//   const [showNewUserForm, setShowNewUserForm] = useState(false);
//   const [resultMessage, setResultMessage] = useState("");
//   const [username, setUsername] = useState("");
//   const [userType, setUserType] = useState("");

//   AWS.config.region = "us-west-1";
//   // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   //   IdentityPoolId: config.identityPoolId,
//   // });

//   const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

//   const sendOTP = (phoneNumber) => {
//     const params = {
//       ClientId: config.clientId,
//       AuthFlow: "CUSTOM_AUTH",
//       AuthParameters: {
//         USERNAME: phoneNumber,
//       },
//     };

//     cognitoIdentityServiceProvider.initiateAuth(params, (err, data) => {
//       if (err) {
//         console.log("Initiate Auth Error:", err);
//         setResultMessage(`Initiate Auth Error: ${err.message}`);
//       } else {
//         if (data.ChallengeName === "CUSTOM_CHALLENGE") {
//           setSession(data.Session);
//           setShowOtpForm(true);
//         } else {
//           console.log("Unexpected Challenge Name:", data.ChallengeName);
//         }
//       }
//     });
//   };

//   const handleSignupSubmit = (event) => {
//     event.preventDefault();
//     const params = {
//       ClientId: config.clientId,
//       Username: phoneNumber,
//       Password: "dummyPassword",
//       UserAttributes: [
//         {
//           Name: "phone_number",
//           Value: phoneNumber,
//         },
//       ],
//     };

//     cognitoIdentityServiceProvider.signUp(params, (err, data) => {
//       if (err) {
//         if (err.code === "UsernameExistsException") {
//           sendOTP(phoneNumber); // User already exists, send OTP
//         } else {
//           console.log("Sign Up Error:", err);
//           setResultMessage(`Sign Up Error: ${err.message}`);
//         }
//       } else {
//         sendOTP(phoneNumber); // New user, send OTP
//       }
//     });
//   };

//   const handleOtpSubmit = (event) => {
//     event.preventDefault();
//     const params = {
//       ChallengeName: "CUSTOM_CHALLENGE",
//       ClientId: config.clientId,
//       ChallengeResponses: {
//         USERNAME: phoneNumber,
//         ANSWER: otp,
//       },
//       Session: session,
//     };
  
//     cognitoIdentityServiceProvider.respondToAuthChallenge(params, (err, data) => {
//       if (err) {
//         console.log("Respond to Auth Challenge Error:", err);
//         setResultMessage("Invalid OTP");
//       } else {
//         if (data.ChallengeName === "CUSTOM_CHALLENGE") {
//           // OTP verification successful, but additional challenge needed (unlikely in this flow)
//           console.log("Additional challenge needed:", data.ChallengeName);
//         } else if (data.AuthenticationResult) {
//           // User authenticated successfully
//           sessionStorage.setItem("phoneNumber", phoneNumber);
//           setShowNewUserForm(true); // Show new user form for additional details
//           setResultMessage(""); // Clear any previous error messages
//         } else {
//           // Handle unexpected case where neither additional challenge nor authentication result is present
//           console.log("Unexpected response:", data);
//           setResultMessage("Unexpected response");
//         }
//       }
//     });
//   };
  

//   const handleNewUserSubmit = (event) => {
//     event.preventDefault();
//     const params = {
//       UserPoolId: config.userPoolId,
//       Username: phoneNumber,
//       UserAttributes: [
//         {
//           Name: "custom:UserName_Type",
//           Value: userType,
//         },
//         {
//           Name: "custom:User_Name",
//           Value: username,
//         },
//       ],
//     };
  
//     cognitoIdentityServiceProvider.adminUpdateUserAttributes(params, (err, data) => {
//       if (err) {
//         console.log("User Attributes Update Error:", err);
//         setResultMessage(`Error: ${err.message}`);
//       } else {
//         setResultMessage("User attributes updated successfully");
//         // Redirect to the upload page or perform any other action
//       }
//     });
//   };
  
//   return (
//     <CoverLayout image={greenBgImage}>
//       <div className="title-container">
//         <MDTypography variant="h1" mb={2} textAlign="center" color="white">
//           Ecoscore
//         </MDTypography>
//       </div>
//       <Container maxWidth="sm">
//         <Box my={4} className="transparent-box">
//           <MDTypography variant="h4" component="h1" gutterBottom color="white" textAlign="center">
//             User Login
//           </MDTypography>
//           {!showOtpForm && !showNewUserForm && (
//             <form onSubmit={handleSignupSubmit} className="form-container">
//               <PhoneInput
//                 defaultCountry="IN"
//                 placeholder="Enter phone number"
//                 value={phoneNumber}
//                 onChange={setPhoneNumber}
//                 className="phone-input"
//                 style={{ fontSize: "20px", padding: "14px" }}
//               />
//               <Button
//                 variant="contained"
//                 color="white"
//                 type="submit"
//                 className="action-button"
//               >
//                 Get OTP
//               </Button>
//             </form>
//           )}
//           {showOtpForm && !showNewUserForm && (
//             <form onSubmit={handleOtpSubmit} className="form-container">
//               <TextField
//                 fullWidth
//                 type="text"
//                 id="otpInput"
//                 label="OTP"
//                 required
//                 value={otp}
//                 onChange={(e) => setOtp(e.target.value)}
//                 margin="normal"
//                 variant="outlined"
//                 className="otp-input"
//               />
//               <Button
//                 variant="contained"
//                 color="white"
//                 type="submit"
//                 className="action-button"
//               >
//                 Verify OTP
//               </Button>
//             </form>
//           )}
//           {showNewUserForm && (
//             <form onSubmit={handleNewUserSubmit} className="form-container">
//               <TextField
//                 fullWidth
//                 type="text"
//                 id="usernameInput"
//                 label="Username"
//                 required
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 margin="normal"
//                 variant="outlined"
//                 className="username-input"
//               />
//               <FormControl fullWidth margin="normal" variant="outlined">
//                 <InputLabel id="userType-label">User Type</InputLabel>
//                 <Select
//                   labelId="userType-label"
//                   id="userType"
//                   value={userType}
//                   onChange={(e) => setUserType(e.target.value)}
//                   label="User Type"
//                 >
//                   <MenuItem value="Individual">Individual</MenuItem>
//                   <MenuItem value="Organization">Organization</MenuItem>
//                 </Select>
//               </FormControl>
//               <Button
//                 variant="contained"
//                 color="white"
//                 type="submit"
//                 className="action-button"
//               >
//                 Submit
//               </Button>
//             </form>
//           )}
//           <Typography variant="body1" color="error" align="center" mt={2}>
//             {resultMessage}
//           </Typography>
//         </Box>
//       </Container>
//     </CoverLayout>
//   );
// };

// export default Login;
