const config = { 
    apiKey : 'oH7r81igrj7FuHcJpyZ8D9B82xJKAy6s91UDizYD',
    GoogleApiKey: 'AIzaSyDIXOB1cJHImRQngw7vnmasrG17brR4_Fc'
  };
export default config;
// const config = { 
//     apiKey : 'tYyHmpJGvC6ftEahT6KB7aWp5kMnlY0uaI4NEF54',
//     GoogleApiKey: 'AIzaSyDIXOB1cJHImRQngw7vnmasrG17brR4_Fc'
//   };
// export default config;



