import React, { useState, useEffect, useRef } from "react";
import * as turf from "@turf/turf";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import config from "layouts/UploadScreen/config";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Forestbg from "assets/images/Forestbg.jpg";
import jsPDF from "jspdf"; // Import jsPDF
import DataTable from "examples/Tables/DataTable";
import TableCell from "@mui/material/TableCell";
import Image2022 from "assets/images/GcCalculation/2022-23.png"; // Add your image paths here
import Image2024 from "assets/images/GcCalculation/2023-24.jpeg"; // Add your image paths here
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const MapContainer = styled("div")({
  height: "500px",
  borderRadius: "10px",
  width: "40%",
  overflow: "hidden",
  margin: "50px",
  display: "block",
  float: "right",
});

const DataTableContainer = styled("div")({
  width: "60%",
  margin: "50px",
});

const GcCalculator = () => {
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [details, setDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [greenCreditsDialogOpen, setGreenCreditsDialogOpen] = useState(false);
  const [viewedPolygonIndex, setViewedPolygonIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sliderValue, setSliderValue] = useState(60); // Add state for slider value
  const mapRef = useRef(null);
  const phoneNumber = sessionStorage.getItem("phoneNumber");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.GoogleApiKey}&callback=initMap&libraries=drawing,places,geometry`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.initMap = () => {
      const initializedMap = new window.google.maps.Map(mapRef.current, {
        center: { lat: 12.3364, lng: 75.9017 },
        zoom: 10.5,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
      });
      setMap(initializedMap);
    };
  }, []);

  const handleOsNumberClick = (coordinates, index) => {
    polygons.forEach((polygon) => polygon.setMap(null));
    const newPolygons = [];

    const polygonCoords = parseCoordinates(coordinates);

    if (
      polygonCoords[0].lat !== polygonCoords[polygonCoords.length - 1].lat ||
      polygonCoords[0].lng !== polygonCoords[polygonCoords.length - 1].lng
    ) {
      polygonCoords.push(polygonCoords[0]);
    }

    const polygon = new window.google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    polygon.setMap(map);
    newPolygons.push(polygon);
    setPolygons(newPolygons);

    const bounds = new window.google.maps.LatLngBounds();
    polygon.getPath().forEach((latLng) => bounds.extend(latLng));
    zoomToPolygon(bounds);

    setViewedPolygonIndex(index);
  };

  const handleViewDetailsClick = (coordinates, item) => {
    const polygonCoords = parseCoordinates(coordinates);

    if (
      polygonCoords[0].lat !== polygonCoords[polygonCoords.length - 1].lat ||
      polygonCoords[0].lng !== polygonCoords[polygonCoords.length - 1].lng
    ) {
      polygonCoords.push(polygonCoords[0]);
    }

    const turfCoords = polygonCoords.map((coord) => [coord.lng, coord.lat]);
    const turfPolygon = turf.polygon([turfCoords]);
    const area = turf.area(turfPolygon);

    const areaHectares = area / 10000;
    const areaAcres = area * 0.000247105;

    let message = "";
    if (areaHectares < 5) {
      message =
        "The land needs to be a minimum of 5 hectares to be eligible for green credit score.";
    }

    setDetails({
      scores: item.ecoscores,
      coordinates: polygonCoords,
      area: area.toFixed(2),
      areaHectares: areaHectares.toFixed(2),
      areaAcres: areaAcres.toFixed(2),
      message,
    });

    setDialogOpen(true);
  };

  const handleViewGreenCreditsClick = () => {
    setGreenCreditsDialogOpen(true);
  };

  const zoomToPolygon = (bounds) => {
    const zoomChangeBoundsListener = window.google.maps.event.addListenerOnce(
      map,
      "bounds_changed",
      () => {
        const zoomLevel = Math.min(map.getZoom(), 15);
        map.setZoom(zoomLevel);
      }
    );

    map.fitBounds(bounds);
    setTimeout(
      () => window.google.maps.event.removeListener(zoomChangeBoundsListener),
      2000
    );
  };

  const handleRefreshClick = () => {
    polygons.forEach((polygon) => polygon.setMap(null));
    setPolygons([]);
    setViewedPolygonIndex(null);
    map.setCenter({ lat: 12.3364, lng: 75.9017 });
    map.setZoom(10.5);
    setDetails(null);
  };

  const parseCoordinates = (coordinates) => {
    const parsedCoordinates = [];
    for (let i = 0; i < coordinates.length; i += 2) {
      const lat = parseFloat(coordinates[i]);
      const lng = parseFloat(
        i + 1 < coordinates.length ? coordinates[i + 1] : 0
      );
      parsedCoordinates.push({ lat, lng });
    }
    return parsedCoordinates;
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseGreenCreditsDialog = () => {
    setGreenCreditsDialogOpen(false);
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    let y = 10;

    doc.text("EcoScore Report", 10, y);
    y += 10;
    doc.text("Coordinates:", 10, y);
    y += 10;
    details?.coordinates.forEach((coord, index) => {
      doc.text(`(${coord.lat}, ${coord.lng})`, 10, y);
      y += 10;
    });
    y += 10;
    doc.text("EcoScores:", 10, y);
    y += 10;
    details?.scores.forEach((score, index) => {
      doc.text(
        `Green credit score for the year ${score.year}: ${score.score}`,
        10,
        y
      );
      y += 10;
    });
    y += 10;
    doc.text(`Area: ${details?.area} square meters`, 10, y);
    y += 10;
    doc.text(`Area in hectares: ${details?.areaHectares} hectares`, 10, y);
    y += 10;
    doc.text(`Area in acres: ${details?.areaAcres} acres`, 10, y);
    y += 10;
    if (details?.message) {
      doc.text(details.message, 10, y);
    }

    doc.save("EcoScore_Report.pdf");
  };

  if (!phoneNumber) {
    navigate("/Login");
    return null;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Author = ({ project }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={0} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {project}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ date, time }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {date} {time}
      </MDTypography>
    </MDBox>
  );

  // Placeholder data
  const data = [
    {
      project: "Project A",
      date: "2024-01-01",
      time: "10:00",
      coordinates: [
        "13.214473576641684",
        "77.04292850204725",
        "13.214431797641227",
        "77.04415695377607",
        "13.213805111775747",
        "77.04409258075971",
        "13.213925226691302",
        "77.04292850204725",
        "13.214473576641684",
        "77.04292850204725",
      ],
      ecoscores: [
        { year: 2022, score: 85 },
        { year: 2023, score: 85 },
        { year: 2024, score: 88 },
      ],
    },
    // Add more static data as needed
  ];

  const rows = data.map((item, index) => {
    const coordinates = item.coordinates;

    return {
      Project: <Author project={item.project} />,
      Date_Time: <Job date={item.date} time={item.time} />,
      Action: (
        <TableCell>
          <Button
            variant="contained"
            style={{
              backgroundColor: "blue",
              color: "white",
              fontSize: "10px",
              padding: "6px 20px",
              minWidth: "110px",
            }}
            onClick={() => handleOsNumberClick(coordinates, index)}
          >
            View Polygon
          </Button>
          {viewedPolygonIndex === index && (
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  fontSize: "10px",
                  padding: "6px 20px",
                  minWidth: "100px",
                  marginLeft: "10px",
                }}
                onClick={() => handleViewDetailsClick(coordinates, item)}
              >
                View Details
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontSize: "10px",
                  padding: "6px 20px",
                  minWidth: "100px",
                  marginLeft: "10px",
                }}
                onClick={handleViewGreenCreditsClick}
              >
                View Green Credits
              </Button>
            </>
          )}
        </TableCell>
      ),
    };
  });

  const columns = [
    { Header: "Project", accessor: "Project", width: "45%", align: "left" },
    { Header: "Date_Time", accessor: "Date_Time", align: "left" },
    { Header: "Action", accessor: "Action", align: "center" },
  ];

  const marks = [
    { value: 0, label: "2022" },
    { value: 88, label: "2024" },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  // Function to get the label based on the slider value
  // const getImageLabel = (value) => {
  //     if (value <= 71) { // Assuming 71 corresponds to 2023
  //       return "2022";
  //     } else if (value <= 80) { // Assuming 80 corresponds to 2024
  //       return "2024";
  //     }
  //     return "";
  //   };

  const getImageLabel = (value) => {
    if (value === 0) {
      return {
        label: "2022",
        content: (
          <div>
            <Typography variant="body1">
              In 2022 the project had started, as you can see the land had no
              trees.
            </Typography>
            <img src={Image2022} alt="2022 Image" width="60%" height="auto" />
          </div>
        ),
      };
    } else if (value === 88) {
      return {
        label: "2024",
        content: (
          <div>
            <Typography variant="body1">
              Two years prior to enrolling to Green credit program, the land is
              eligible for 88 green credits.
            </Typography>
            <img src={Image2024} alt="2024 Image" width="60%" height="auto" />
          </div>
        ),
      };
    }
    return { label: "", content: null };
  };

  return (
    <DashboardLayout backgroundImage={Forestbg}>
      <DashboardNavbar />

      <Container style={{ display: "flex" }}>
        <DataTableContainer>
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                style={{ flexGrow: 1, color: "#ffffff" }}
              >
                EcoScore
              </Typography>
            </Toolbar>
          </AppBar>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={handleRefreshClick}
            >
              Refresh
            </Button>
          </Box>

          <Box mt={2}>
            <DataTable
              table={{ columns, rows }}
              isSorted={true}
              entriesPerPage={true}
              showTotalEntries={true}
              noEndBorder
            />
          </Box>
        </DataTableContainer>
        <MapContainer ref={mapRef} id="map" />
      </Container>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">Coordinates:</Typography>
            <Typography>
              {details?.coordinates
                .map((coord) => `(${coord.lat}, ${coord.lng})`)
                .join(", ")}
            </Typography>
            <Typography variant="h6">EcoScores:</Typography>
            <ul>
              {details?.scores.map((score) => (
                <li key={score.year}>
                  Green credit score for the year {score.year}: {score.score}
                </li>
              ))}
            </ul>
            <Typography variant="h6">Area:</Typography>
            <Typography>Area: {details?.area} square meters</Typography>
            <Typography>
              Area in hectares: {details?.areaHectares} hectares{" "}
              {details?.message}
            </Typography>
            <Typography>Area in acres: {details?.areaAcres} acres</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={generatePDF} color="primary" variant="contained">
            Download Report
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={greenCreditsDialogOpen}
        onClose={handleCloseGreenCreditsDialog}
      >
        <DialogTitle>Green Credits</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ mt: 5, width: 350 }}>
              <Slider
                aria-label="Custom marks"
                defaultValue={0}
                getAriaValueText={valuetext}
                step={null}
                valueLabelDisplay="auto"
                marks={marks}
                min={0}
                max={88}
                value={sliderValue}
                onChange={handleSliderChange}
              />
            </Box>
            <Box mt={2}>{getImageLabel(sliderValue).content}</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseGreenCreditsDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default GcCalculator;
