

// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Icon from "@mui/material/Icon";
// import { faGears } from "@fortawesome/free-solid-svg-icons";
// import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
// import Dashboard from "layouts/dashboard";
// import LandingPage from "layouts/landing";
// import UploadScreen from "layouts/UploadScreen/UploadScreen";
// import DataTable from "layouts/tables";
// import Basic from "layouts/authentication/sign-in";

// const routes = [  
//    {
//     type: "collapse",
//     name: "Home",
//     key: "Home",
//     icon: <Icon fontSize="small">person</Icon>,
//     route: "/home",
//     component: <LandingPage />,
//   },
//   {
//     type: "collapse",
//     name: "Dashboard",
//     key: "dashboard",
//     icon: <Icon fontSize="small">dashboard</Icon>,
//     route: "/dashboard",
//     component: <Dashboard />,
//   },
//   {
//     type: "collapse",
//     name: "Upload",
//     key: "Upload",
//     icon: <FontAwesomeIcon icon={faChartSimple} />,
//     route: "/Upload",
//     component: <UploadScreen/>,
//   },
//   {
//     type: "collapse",
//     name: "Reports",
//     key: "Reports",
//     icon: <FontAwesomeIcon icon={faChartSimple} />,
//     route: "/Reports",
//     component: <DataTable/>,
//   },

//   // {
//   //   type: "collapse",
//   //   name: "Billing",
//   //   key: "billing",
//   //   icon: <Icon fontSize="small">receipt_long</Icon>,
//   //   route: "/billing",
//   //   component: <Billing />,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "RTL",
//   //   key: "rtl",
//   //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
//   //   route: "/rtl",
//   //   component: <RTL />,
//   // },
//   {
//     type: "collapse",
//     name: "Settings",
//     key: "Settings",
//     icon: <FontAwesomeIcon icon={faGears} />,
//     route: "/Settings",
//     component: <Notifications />,
//   },
//   {
//     type: "collapse",
//     name: "Profile",
//     key: "profile",
//     icon: <Icon fontSize="small">person</Icon>,
//     route: "/profile",
//     component: <Profile />,
//   },
//   {
//     type: "collapse",
//     name: "Login",
//     key: "Login",
//     icon: <Icon fontSize="small">person</Icon>,
//     route: "/Login",
//     component: <Basic />,
//   },
//   {
//     type: "collapse",
//     name: "logout",
//     key: "sign-up",
//     icon: <Icon fontSize="small">assignment</Icon>,
//     route: "/authentication/sign-up",
//     component: <SignUp />,
//   },
// ];

// export default routes;
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@mui/material/Icon";
import { faGears, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "layouts/dashboard";
import LandingPage from "layouts/landing";
import UploadScreen from "layouts/UploadScreen/submit";
import ReportTable from "layouts/tables";
import Basic from "layouts/authentication/sign-in";
import Submit from "layouts/UploadScreen/submit";
import GreenCredits from "./cardsInfo/greencredits";
import Carboncredits from "./cardsInfo/carboncredits";
import Ecoscore from "./cardsInfo/ecoscore"
import AboutUs from "layouts/AboutUs";
import MapIcon from '@mui/icons-material/Map';
import TableChartIcon from '@mui/icons-material/TableChart';
import CalculateIcon from '@mui/icons-material/Calculate';
import GcCalculator from "layouts/GreenCreditCalculator/GcCalculator";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "Home",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/home",
    component: <LandingPage />,
    protected: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Submit Land Details",
    key: "Submit Land Details",
    icon: <MapIcon fontSize="small" />,
    route: "/Upload",
    component: <Submit />,
    protected: true,
  },
  {
    type: "collapse",
    name: "View Land Details",
    key: "View Land Details",
    icon: <TableChartIcon fontSize="small" />,
    route: "/Reports",
    component: <ReportTable />,
    protected: true,
  },
  //green credit score calculator prototype
  {
    type: "collapse",
    name: "green credit calculator",
    key: "green credit calculator",
    icon: <CalculateIcon fontSize="medium" />,
    route: "/GcCalculator",
    component: <GcCalculator />,
    protected: true,
  },
  {
    // type: "collapse",
    name: "Settings",
    key: "Settings",
    icon: <FontAwesomeIcon icon={faGears} />,
    route: "/Settings",
    component: <Notifications />,
    protected: true,
  },
  {
    // type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    protected: true,
  },
  {
    // type: "collapse",
    name: "Login",
    key: "Login",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/Login",
    component: <Basic />,
    protected: false,
  },
  {
    // type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "greencredits",
    // key: "greencredits",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "/greencredits",
    component: <GreenCredits />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "carboncredits",
    // key: "carboncredits",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "/carboncredits",
    component: <Carboncredits />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "ecoscore",
    // key: "ecoscore",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "/ecoscore",
    component: <Ecoscore />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "AboutUs",
    // key: "AboutUs",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "AboutUs",
    component: <AboutUs />,
    protected: false,
  },

];

export default routes;