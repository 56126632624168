import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import MDTypography from 'components/MDTypography';
// import MDBox from 'components/MDBox';
import './ImageStatisticsCard.css';  // Import the CSS file

const ImageStatisticsCard = ({ image, title, count, percentage }) => {
  return (
    <Card className="image-statistics-card">
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img src={image} alt={title} className="card-image" />
          </Grid>
          <Grid item xs>
            <MDTypography variant="h6" gutterBottom>
              {title}
            </MDTypography>
            <MDTypography variant="h4" color="textPrimary">
              {count}
            </MDTypography>
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="body2" color={percentage.color}>
                {percentage.amount}
              </MDTypography>
              <MDTypography variant="body2" color="textSecondary">
                &nbsp;{percentage.label}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ImageStatisticsCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImageStatisticsCard;