import React from 'react';
import './carboncredits.css';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Cardbg from '../assets/images/CardBg.jpg';
import { useNavigate } from 'react-router-dom';

const GreenCredits = () => {


  const navigate = useNavigate()
  const phoneNumber = sessionStorage.getItem('phoneNumber');


  const handleNavigateToHome = () => {
    navigate('/home');
  };
  if (!phoneNumber) {
    // If not available, navigate to the login page
    navigate('/Login');
    return null; // Return null to prevent rendering the rest of the component
  }

  return (
    <DashboardLayout backgroundImage={Cardbg}> 
    <div className="container">
      <button className="exit-button" onClick={handleNavigateToHome}>Back</button>
      <h1>Green Credits</h1>
      <p>
        Green credits are a key component of national and international efforts to mitigate the growth in concentrations of greenhouse gases (GHGs). One Green credit is equal to one tonne of Green dioxide, or in some markets, Green dioxide equivalent gases. These credits can be traded and sold, and they incentivize companies to reduce their Green footprint.
      </p>
      <p>
        The concept of Green credits came into existence as a result of the Kyoto Protocol. The goal is to limit the increase of Green dioxide levels in the atmosphere. Companies or countries are assigned a certain number of credits, which represent the amount of Green dioxide they are allowed to emit. If they need to emit more, they can purchase credits from others who have managed to stay under their limit.
      </p>
      <p>
        Investing in Green credits helps promote sustainability by encouraging industries to invest in cleaner technologies and reduce their emissions. The funds generated from selling these credits can be used to finance renewable energy projects, forest conservation, and other environmental initiatives. As the world moves towards a greener future, Green credits play a crucial role in the global strategy to combat climate change.
      </p>
    </div>
     </DashboardLayout>
  );
};

export default GreenCredits;