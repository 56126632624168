import React from 'react';
import { Grid, Link } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import greenBgImage from '../assets/images/greenbg2.jpeg';
import greenCreditsImage from '../assets/images/green.jpeg'; // Placeholder image
import carbonCreditsImage from '../assets/images/carbon.jpeg'; // Placeholder image
import ecoScoreImage from '../assets/images/ecos.jpeg'; // Placeholder image
import ImageStatisticsCard from 'examples/Cards/StatisticsCards/ImageStaticsCard/ImageStatisticsCard';
import { useUser } from 'context/UserContext';
import { useUserDispatch } from 'context/UserContext';
import { useNavigate } from 'react-router-dom';
import CarbonPricesWidget from './UploadScreen/CarbonPrices';


const LandingPage = () => {
  const navigate = useNavigate();

  const phoneNumber = sessionStorage.getItem('phoneNumber');
  console.log(phoneNumber)

  if (!phoneNumber) {
    // If not available, navigate to the login page
    navigate('/Login');
    return null; // Return null to prevent rendering the rest of the component
  }
  const handleNavigateToGreenCredits = () => {
    navigate('/greencredits');
  };

  const handleNavigateToCarbonCredits = () => {
    navigate('/carboncredits');
  };
  const handleNavigateToEcoscore = () => {
    navigate('/ecoscore');
  };
  return (
    <DashboardLayout  backgroundImage={greenBgImage} >
 
      <div>
        <DashboardNavbar />
        <MDBox py={3} px={2} textAlign="center">
          <MDTypography variant="h3" mb={2}>
            Welcome to Ecoscore
            {/* <p>Email: {username}</p> */}
          </MDTypography>
          <MDTypography variant="body1" mb={4}>
          Stay informed with our homepage, where you can track the impact of your environmental initiatives, including Green Credits, Carbon Credits, and EcoScore. We provide real-time statistics and trends to help you monitor progress and ensure your efforts are contributing to a positive environmental impact. Join us in making a difference by staying updated and informed every step of the way. 
          </MDTypography>
          <MDTypography variant="body1" mb={4}>
            <strong>How do we calculate the Eco-score?</strong>
            <br />
            At Dhruthzuci Technology Solutions, we've developed an innovative app that calculates Eco Scores, providing a comprehensive assessment of the environmental impact of food products from farm to fork. Our methodology combines rigorous Life Cycle Assessment (LCA) with a bonus-malus system that evaluates factors such as climate change, resource depletion, and biodiversity. This holistic approach not only empowers consumers with clear and detailed insights into the ecological footprint of their food choices but also encourages sustainability throughout the supply chain. Join us in making informed decisions that prioritize both the planet and your health.
          </MDTypography>
          {/* Add ImageStatisticsCard components for Green Credits, Carbon Credits, and EcoScore */}
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh" > 
            <Grid container spacing={3} justifyContent="center" >
              <Grid item xs={12} md={4} lg={4} onClick={handleNavigateToGreenCredits}>
                <MDBox mb={1.5}>
                  <ImageStatisticsCard 
                    image={greenCreditsImage}
                    title="Green Credits"
                    count="1250"
                    percentage={{
                      color: "success",
                      amount: "+15%",
                      label: "since last month",
                    }
                  }
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4} onClick={handleNavigateToCarbonCredits}>
                <MDBox mb={1.5}>
                  <ImageStatisticsCard 
                    image={carbonCreditsImage}
                    title="Carbon Credits"
                    count="980"
                    percentage={{
                      color: "success",
                      amount: "+10%",
                      label: "since last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4} onClick={handleNavigateToEcoscore}>
                <MDBox mb={1.5}>
                  <ImageStatisticsCard
                    image={ecoScoreImage}
                    title="EcoScore"
                    count="85"
                    percentage={{
                      color: "success",
                      amount: "+8%",
                      label: "since last month",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {/* <CarbonPricesWidget/> */}
        <Footer />
      </div>
    {/* ) : (
      <MDBox style={{ padding: "20px" }}>
        <Link href="/Login">
          You have been Logged Out. Click to login again.
        </Link>
      </MDBox>
    )} */}
  </DashboardLayout>
  );
}

export default LandingPage;