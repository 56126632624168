import React, { useState, useEffect, useRef } from "react";
import * as turf from "@turf/turf";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import config from "layouts/UploadScreen/config";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Forestbg from "assets/images/Forestbg.jpg";
import jsPDF from "jspdf"; // Import jsPDF
import DataTable from "examples/Tables/DataTable";
import TableCell from "@mui/material/TableCell";
import Footer from "examples/Footer";

const MapContainer = styled("div")({
  height: "500px",
  borderRadius: "10px",
  width: "40%",
  overflow: "hidden",
  margin: "50px",
  display: "block",
  float: "right",
});

const DataTableContainer = styled("div")({
  width: "60%",
  margin: "50px",
});

const ReportTable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [map, setMap] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [details, setDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewedPolygonIndex, setViewedPolygonIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const mapRef = useRef(null);
  const phoneNumber = sessionStorage.getItem('phoneNumber');

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.GoogleApiKey}&callback=initMap&libraries=drawing,places,geometry`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.initMap = () => {
      const initializedMap = new window.google.maps.Map(mapRef.current, {
        center: { lat: 12.3364, lng: 75.9017 },
        zoom: 10.5,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
      });
      setMap(initializedMap);
    };

    const url = `https://2tydxkbme3.execute-api.ap-south-1.amazonaws.com/dev/usersubmitted?phone=${encodeURIComponent(
      phoneNumber
    )}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const responseBody = JSON.parse(data["body-json"]["body"]);
        console.log('responseBody', responseBody);
        setData(Array.isArray(responseBody) ? responseBody : []);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleOsNumberClick = (coordinates, index) => {
    polygons.forEach((polygon) => polygon.setMap(null));
    const newPolygons = [];

    const polygonCoords = parseCoordinates(coordinates);

    if (
      polygonCoords[0].lat !== polygonCoords[polygonCoords.length - 1].lat ||
      polygonCoords[0].lng !== polygonCoords[polygonCoords.length - 1].lng
    ) {
      polygonCoords.push(polygonCoords[0]);
    }

    const polygon = new window.google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    polygon.setMap(map);
    newPolygons.push(polygon);
    setPolygons(newPolygons);

    const bounds = new window.google.maps.LatLngBounds();
    polygon.getPath().forEach((latLng) => bounds.extend(latLng));
    zoomToPolygon(bounds);

    setViewedPolygonIndex(index);
  };

  const handleViewDetailsClick = (coordinates, item) => {
    const polygonCoords = parseCoordinates(coordinates);

    if (
      polygonCoords[0].lat !== polygonCoords[polygonCoords.length - 1].lat ||
      polygonCoords[0].lng !== polygonCoords[polygonCoords.length - 1].lng
    ) {
      polygonCoords.push(polygonCoords[0]);
    }

    const turfCoords = polygonCoords.map((coord) => [coord.lng, coord.lat]);
    const turfPolygon = turf.polygon([turfCoords]);
    const area = turf.area(turfPolygon);

    const areaHectares = area / 10000;
    const areaAcres = area * 0.000247105;

    let message = "";
    if (areaHectares < 5) {
      message =
        "The land needs to be a minimum of 5 hectares to be eligible for green credit score.";
    }

    setDetails({
      scores: item.ecoscores,
      coordinates: polygonCoords,
      area: area.toFixed(2),
      areaHectares: areaHectares.toFixed(2),
      areaAcres: areaAcres.toFixed(2),
      message,
    });

    setDialogOpen(true);
  };


  const zoomToPolygon = (bounds) => {
    const zoomChangeBoundsListener = window.google.maps.event.addListenerOnce(
      map,
      "bounds_changed",
      () => {
        const zoomLevel = Math.min(map.getZoom(), 15);
        map.setZoom(zoomLevel);
      }
    );

    map.fitBounds(bounds);
    setTimeout(
      () => window.google.maps.event.removeListener(zoomChangeBoundsListener),
      2000
    );
  };

  const handleRefreshClick = () => {
    polygons.forEach((polygon) => polygon.setMap(null));
    setPolygons([]);
    setViewedPolygonIndex(null);
    map.setCenter({ lat: 12.3364, lng: 75.9017 });
    map.setZoom(10.5);
    setDetails(null);
  };

  const parseCoordinates = (coordinates) => {
    const parsedCoordinates = [];
    for (let i = 0; i < coordinates.length; i += 2) {
      const lat = parseFloat(coordinates[i]);
      const lng = parseFloat(i + 1 < coordinates.length ? coordinates[i + 1] : 0);
      parsedCoordinates.push({ lat, lng });
    }
    return parsedCoordinates;
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    let y = 10;

    doc.text("EcoScore Report", 10, y);
    y += 10;
    doc.text("Coordinates:", 10, y);
    y += 10;
    details?.coordinates.forEach((coord, index) => {
      doc.text(`(${coord.lat}, ${coord.lng})`, 10, y);
      y += 10;
    });
    y += 10;
    doc.text("EcoScores:", 10, y);
    y += 10;
    details?.scores.forEach((score, index) => {
      doc.text(`Green credit score for the year ${score.year}: ${score.score}`, 10, y);
      y += 10;
    });
    y += 10;
    doc.text(`Area: ${details?.area} square meters`, 10, y);
    y += 10;
    doc.text(`Area in hectares: ${details?.areaHectares} hectares`, 10, y);
    y += 10;
    doc.text(`Area in acres: ${details?.areaAcres} acres`, 10, y);
    y += 10;
    if (details?.message) {
      doc.text(details.message, 10, y);
    }

    doc.save("EcoScore_Report.pdf");
  };



  if (!phoneNumber) {
    navigate('/Login');
    return null;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Author = ({ project }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={0} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {project}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ date, time }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {date} {time}
      </MDTypography>
    </MDBox>
  );

  const rows = Array.isArray(data) ? data.map((item, index) => {
    const coordinates = item.coordinates;

    return {
      Project: <Author project={item.project} />,
      Date_Time: <Job date={item.date} time={item.time} />,
      Action: (
        <TableCell>
          <Button
            variant="contained"
            style={{
              backgroundColor: "blue",
              color: "white",
              fontSize: "10px",
              padding: "6px 20px",
              minWidth: "110px",
            }}
            onClick={() => handleOsNumberClick(coordinates, index)}
          >
            View Polygon
          </Button>
          {viewedPolygonIndex === index && (
            <Button
              variant="contained"
              style={{
                backgroundColor: "blue",
                color: "white",
                fontSize: "10px",
                padding: "6px 20px",
                minWidth: "100px",
                marginLeft: "10px",
              }}
              onClick={() => handleViewDetailsClick(coordinates, item)}
            >
              View Details
            </Button>
          )}
        </TableCell>
      ),
    };
  }) : [];

  const columns = [
    { Header: "Project", accessor: "Project", width: "45%", align: "left" },
    { Header: "Date_Time", accessor: "Date_Time", align: "left" },
    { Header: "Action", accessor: "Action", align: "center" },
  ];

  return (
    <DashboardLayout backgroundImage={Forestbg}>
      <Container style={{ display: 'flex' }}>
        <DataTableContainer>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" style={{ flexGrow: 1, color: '#ffffff' }}>
                EcoScore
              </Typography>
            </Toolbar>
          </AppBar>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={handleRefreshClick}
            >
              Refresh
            </Button>
          </Box>

          <Box mt={2}>
            <DataTable
              table={{ columns, rows }}
              isSorted={true}
              entriesPerPage={true}
              showTotalEntries={true}
              noEndBorder
            />
          </Box>
        </DataTableContainer>
        <MapContainer ref={mapRef} id="map" />
      </Container>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">Coordinates:</Typography>
            <Typography>
              {details?.coordinates
                .map((coord) => `(${coord.lat}, ${coord.lng})`)
                .join(", ")}
            </Typography>
            <Typography variant="h6">EcoScores:</Typography>
            <ul>
              {details?.scores.map((score) => (
                <li key={score.year}>
                  Green credit score for the year {score.year}: {score.score}
                </li>
              ))}
            </ul>
            <Typography variant="h6">Area:</Typography>
            <Typography>Area: {details?.area} square meters</Typography>
            <Typography>
              Area in hectares: {details?.areaHectares} hectares{" "}
              {details?.message}
            </Typography>
            <Typography>Area in acres: {details?.areaAcres} acres</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={generatePDF} color="primary" variant="contained">
            Download Report
          </Button>
        </DialogActions>
      </Dialog>

      {/* <MDBox>
        <MDBox mt={3}>
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <MDTypography variant="h6" mb={1} style={{ color: "white" }}>
              Instructions:
            </MDTypography>
            <ul
              className="bullet-list"
              style={{
                color: "white",
                listStyleType: "disc",
                paddingLeft: "20px",
              }}
            >
              <li>Click on Request Id to View polygon</li>
            </ul>
          </Box>
        </MDBox>
      </MDBox> */}

      <Footer />
    </DashboardLayout>
  );
};

export default ReportTable;