import React, { useEffect } from 'react';

function CarbonPricesWidget() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://carboncredits.com/live-carbon-prices/index.js.php';
    script.async = true;
    document.getElementById('carbon-prices').appendChild(script);

    return () => {
      // Cleanup script when the component unmounts
      document.getElementById('carbon-prices').removeChild(script);
    };
  }, []);

  return <div id="carbon-prices"></div>;
}

export default CarbonPricesWidget;
