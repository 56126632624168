import React from 'react';
import './carboncredits.css';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import CardsBg2 from '../assets/images/CardsBg2.jpg';
import { useNavigate } from 'react-router-dom';

const GreenCredits = () => {
  const phoneNumber = sessionStorage.getItem('phoneNumber');

  const navigate = useNavigate()
  if (!phoneNumber) {
    // If not available, navigate to the login page
    navigate('/Login');
    return null; // Return null to prevent rendering the rest of the component
  }

  const handleNavigateToHome = () => {
    navigate('/home');
  };

  return (
    <DashboardLayout backgroundImage={CardsBg2}> 
      <div className="container">
        <button className="exit-button" onClick={handleNavigateToHome}>Back</button>
        <h1>Ecoscore</h1>
        <p>
        The Eco Score is an environmental rating system designed to provide consumers with clear and concise information about the ecological impact of products and services. This metric evaluates various factors such as carbon footprint, resource usage, pollution levels, and recyclability to determine the overall environmental performance of a product. By summarizing this information into an easily understandable score, often represented as a letter grade (A to E) or numerical scale (1 to 100), the Eco Score empowers consumers to make more sustainable choices. This transparency encourages manufacturers to adopt greener practices and drives market demand for environmentally friendly products.
        </p>
        <p>
        Incorporating Eco Scores into consumer decision-making processes benefits both the environment and businesses. For consumers, Eco Scores simplify the complexities of environmental impact, making it easier to compare products and select those with lower ecological footprints. For businesses, achieving high Eco Scores can enhance brand reputation and appeal to environmentally conscious customers, potentially leading to increased market share. Furthermore, the adoption of Eco Scores can stimulate innovation in product design and supply chain management, as companies strive to improve their scores by reducing emissions, conserving resources, and minimizing waste. Overall, the Eco Score system fosters a culture of sustainability, promoting a greener economy and helping to mitigate the impacts of climate change.
        </p>
        <p>
        </p>
      </div>
    </DashboardLayout>
  );
};

export default GreenCredits;