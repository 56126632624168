
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, DrawingManager, Autocomplete } from '@react-google-maps/api';
import config from './config';
import { useNavigate } from 'react-router-dom';
import { TextField, Link as MuiLink, Button, Box } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { v4 as uuidv4 } from 'uuid';
import './submit.css';
import Forestbg from 'assets/images/Forestbg.jpg';
import IndianBorderConfig from './Indiaboundary';
import { styled } from '@mui/system';
import IndiaBoundaryGeoJSON from './IndiaBoundaryGeoJSON2.json';
import { AcUnit } from '@mui/icons-material';
import Footer from "examples/Footer";


const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    color: 'black',
  },
  '& .MuiInputLabel-root': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      opacity: 0.8,
    },
    '&:hover fieldset': {
      borderColor: 'black',
      opacity: 0.8,
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
      opacity: 0.8,
    },
  },
});

const libraries = ['drawing', 'places', 'geometry'];
const mapContainerStyle = {
  height: '500px',
  width: '100%',
};

const center = {
  lat: 20.5937,
  lng: 78.9629,
};

// const indiaBoundaryCoordinates = [
//   { lat: 35.5087, lng: 76.8555 },
//   { lat: 35.5087, lng: 97.3954 },
//   { lat: 6.7471, lng: 97.3954 },
//   { lat: 6.7471, lng: 68.1471 },
//   { lat: 35.5087, lng: 68.1471 },
// ];
// Extract coordinates from the GeoJSON file and handle multiple polygons
const indiaBoundaryPolygons = IndiaBoundaryGeoJSON.features.flatMap(feature =>
  feature.geometry.coordinates.map(polygon => 
    polygon.map(coord => coord.map(point => ({ lat: point[1], lng: point[0] })))
  )
);


const Submit = () => {
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [freeShape, setFreeShape] = useState(null);
  const [projectName, setProjectName] = useState('');
  const autocompleteRef = useRef(null);
  const [uniqueId, setUniqueId] = useState('');
  const [submitted, setSubmitted] = useState(false); // State to track submission status
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: config.GoogleApiKey,
    libraries,
  });
  const phoneNumber = sessionStorage.getItem('phoneNumber');
 

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    setUniqueId(uuidv4()); // Generate a unique ID
  }, []);

  const onLoad = useCallback((map) => {
    setMap(map);
  
    // Create polygons for each set of coordinates
    const boundaryPolygons = indiaBoundaryPolygons.map(polygonCoordinates => 
      new window.google.maps.Polygon({
        paths: polygonCoordinates,
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#000000',
        fillOpacity: 0.15,
        map: map,
      })
    );
  
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      },
    });
  
    drawingManager.setMap(map);
  
    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
        const path = event.overlay.getPath();
        event.overlay.setMap(null);
        const polygonBounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < path.getLength(); i++) {
          polygonBounds.extend(path.getAt(i));
        }
        const polygonCenter = polygonBounds.getCenter();
  
        const isWithinBoundary = boundaryPolygons.some(boundaryPolygon => 
          window.google.maps.geometry.poly.containsLocation(polygonCenter, boundaryPolygon)
        );
  
        if (isWithinBoundary) {
          const newShape = new window.google.maps.Polygon({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: map,
            path: path,
          });
          setFreeShape(newShape);
        } else {
          alert('Location is outside India. Please mark a location within India.');
          event.overlay.setMap(null);
        }
      }
    });
  }, []);  

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
    autocomplete.setBounds(new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(6.7471, 68.1471),
      new window.google.maps.LatLng(35.5087, 97.3954),
    ));
    autocomplete.setOptions({ strictBounds: true, types: ['geocode'] });
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
  
    if (!place || !place.geometry || !place.geometry.location) {
      alert(`No details available for input: '${place ? place.name : 'unknown'}'`);
      console.error('Place details are missing:', place);
      return;
    }
  
    const location = place.geometry.location;
    if (!location) {
      alert('Location data is not available.');
      console.error('Location data is missing:', place);
      return;
    }
  
    const boundaryPolygons = indiaBoundaryPolygons.map(polygonCoordinates => 
      new window.google.maps.Polygon({
        paths: polygonCoordinates,
      })
    );
  
    const isWithinBoundary = boundaryPolygons.some(boundaryPolygon => 
      window.google.maps.geometry.poly.containsLocation(location, boundaryPolygon)
    );
  
    if (!isWithinBoundary) {
      alert('Location is outside India. Please select a location within India.');
      console.error('Location is outside India:', location);
      return;
    }
  
    map.setCenter(location);
    map.setZoom(15);
  };
  

  const refreshMap = () => {
    if (freeShape) {
      freeShape.setMap(null);
    }
    setFreeShape(null);
    setSubmitted(false); // Reset submitted state on map refresh
  };

  const generateRandomScores = () => {
    return [
      { year: '2021', score: Math.floor(Math.random() * 101).toString() },
      { year: '2022', score: Math.floor(Math.random() * 101).toString() },
      { year: '2023', score: Math.floor(Math.random() * 101).toString() },
      { year: '2024', score: Math.floor(Math.random() * 101).toString() },
    ];
  };

  const submitForm = async () => {
    if (submitted) {
      alert('Data has already been submitted. Please refresh the page to submit again.');
      return;
    }

    if (!projectName) {
      alert("Please enter a project name before submitting.");
      return;
    }

    if (!freeShape) {
      alert('Please draw a shape on the map before submitting.');
      return;
    }

    const path = freeShape.getPath();
    const coordinates = {};
    for (let i = 0; i < path.getLength(); i++) {
      const markerLabel = `Marker #${i + 1}`;
      const latLng = {
        lat: path.getAt(i).lat().toFixed(15),
        lng: path.getAt(i).lng().toFixed(15),
      };
      coordinates[markerLabel] = latLng;
    }

    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const date = `${day}-${month}-${year}`;

    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const time = `${hours}:${minutes} ${ampm}`;

    const ecoscores = generateRandomScores();

    const payload = {
      RequestId: uniqueId,
      coordinates: coordinates,
      project: projectName,
      phoneNumber: phoneNumber,
      ecoscores: ecoscores,
      date: date,
      time: time
    };

    try {
      const submitResponse = await fetch('https://2tydxkbme3.execute-api.ap-south-1.amazonaws.com/dev/litigation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': config.apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!submitResponse.ok) {
        alert('There was an error submitting the data.');
        return;
      }

      const responseData = await submitResponse.json();
      console.log('Response Data:', JSON.stringify(responseData, null, 2));

      alert('Data submitted successfully!');
      setSubmitted(true); // Set submitted state to true after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the data.');
    }
  };

  const viewSubmissions = () => {
    navigate(`/Reports?phone=${encodeURIComponent(phoneNumber)}`);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!phoneNumber) {
    navigate('/Login');
    return null;
  }

  return (
    <DashboardLayout backgroundImage={Forestbg}>
      <DashboardNavbar />
      <MDBox py={3} px={2}>
        <MDTypography variant="h3" mb={2} textAlign="center" color="white">
          EcoScore
        </MDTypography>

        <MDBox mb={3}>
          <script
            type="text/javascript"
            src="https://carboncredits.com/live-carbon-prices/index.js.php"
            async=""
          />
        </MDBox>
        <MDBox my={3} style={{ width: "50%", margin: "0 auto" }}>
          <StyledTextField
            fullWidth
            type="tel"
            id="projectName"
            label="Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            margin="normal"
            variant="outlined"
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: "8px" }}
          />
        </MDBox>
        <MDBox my={3}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={5}
            mapTypeId="hybrid"
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Autocomplete
              onLoad={onLoadAutocomplete}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                placeholder="Enter a location"
                style={{
                  boxSizing: 'border-box',
                  border: '1px solid transparent',
                  width: '240px',
                  height: '32px',
                  padding: '0 12px',
                  borderRadius: '3px',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                  fontSize: '14px',
                  outline: 'none',
                  textOverflow: 'ellipses',
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-120px',
                }}
              />
            </Autocomplete>
          </GoogleMap>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" mb={3}>
          <Button
            variant="contained"
            style={{ backgroundColor: 'blue', color: 'white' }}
            onClick={refreshMap}
          >
            Refresh Map
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: 'blue', color: 'white' }}
            onClick={submitForm}
          >
            Submit
          </Button>
        </MDBox>
        <MDBox textAlign="center" mb={3}>
          <MDTypography variant="h6" mb={3} style={{ color: "white" }}>
            To view your submitted data{' '}
            <MuiLink
              component="button"
              onClick={viewSubmissions}
              sx={{ color: "white", "&:hover": { textDecoration: "underline" } }}
            >
              Click here
            </MuiLink>
          </MDTypography>
        </MDBox>
        <MDBox mt={3}>
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <MDTypography variant="h6" mb={1} style={{ color: 'white' }}>
              Instructions:
            </MDTypography>
            <ul
              className="bullet-list"
              style={{
                color: 'white',
                listStyleType: 'disc',
                paddingLeft: '20px',
              }}
            >
              <li>Please provide project Name</li>
              <li>Mark coordinates with in India</li>

            </ul>
          </Box>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Submit;

// import React, { useState, useRef, useCallback, useEffect } from 'react';
// import { GoogleMap, useJsApiLoader, DrawingManager, Autocomplete } from '@react-google-maps/api';
// import config from './config';
// import { useNavigate } from 'react-router-dom';
// import { TextField, Link as MuiLink, Button, Box } from '@mui/material';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import MDBox from 'components/MDBox';
// import MDTypography from 'components/MDTypography';
// import { v4 as uuidv4 } from 'uuid';
// import './submit.css';
// import Forestbg from 'assets/images/Forestbg.jpg';
// import { styled } from '@mui/system';
// import IndiaBoundaryGeoJSON from './IndiaBoundaryGeoJSON.json';

// const StyledTextField = styled(TextField)({
//   '& .MuiInputBase-root': {
//     color: 'black',
//   },
//   '& .MuiInputLabel-root': {
//     color: 'black',
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: 'black',
//       opacity: 0.8,
//     },
//     '&:hover fieldset': {
//       borderColor: 'black',
//       opacity: 0.8,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: 'black',
//       opacity: 0.8,
//     },
//   },
// });

// const libraries = ['drawing', 'places', 'geometry'];
// const mapContainerStyle = {
//   height: '500px',
//   width: '100%',
// };

// const center = {
//   lat: 20.5937,
//   lng: 78.9629,
// };

// // Extract coordinates from the GeoJSON file
// const indiaBoundaryCoordinates = IndiaBoundaryGeoJSON.features.flatMap(feature => 
//   feature.geometry.coordinates.flat(2).map(coord => ({ lat: coord[1], lng: coord[0] }))
// );

// const Submit = () => {
//   const navigate = useNavigate();
//   const [map, setMap] = useState(null);
//   const [freeShape, setFreeShape] = useState(null);
//   const [projectName, setProjectName] = useState('');
//   const autocompleteRef = useRef(null);
//   const [uniqueId, setUniqueId] = useState('');
//   const [submitted, setSubmitted] = useState(false); // State to track submission status
//   const { isLoaded, loadError } = useJsApiLoader({
//     googleMapsApiKey: config.GoogleApiKey,
//     libraries,
//   });
//   const phoneNumber = sessionStorage.getItem('phoneNumber');

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     setUniqueId(uuidv4()); // Generate a unique ID
//   }, []);

//   const onLoad = useCallback((map) => {
//     setMap(map);

//     const boundaryPolygon = new window.google.maps.Polygon({
//       paths: indiaBoundaryCoordinates,
//       strokeColor: '#000000',
//       strokeOpacity: 0.8,
//       strokeWeight: 2,
//       fillColor: '#000000',
//       fillOpacity: 0.15,
//       map: map,
//     });

//     const drawingManager = new window.google.maps.drawing.DrawingManager({
//       drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
//       drawingControl: true,
//       drawingControlOptions: {
//         position: window.google.maps.ControlPosition.TOP_CENTER,
//         drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
//       },
//       polygonOptions: {
//         strokeColor: '#FF0000',
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: '#FF0000',
//         fillOpacity: 0.35,
//       },
//     });

//     drawingManager.setMap(map);

//     window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
//       if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
//         const path = event.overlay.getPath();
//         event.overlay.setMap(null);
//         const polygonBounds = new window.google.maps.LatLngBounds();
//         for (let i = 0; i < path.getLength(); i++) {
//           polygonBounds.extend(path.getAt(i));
//         }
//         const polygonCenter = polygonBounds.getCenter();
//         if (window.google.maps.geometry.poly.containsLocation(polygonCenter, boundaryPolygon)) {
//           const newShape = new window.google.maps.Polygon({
//             strokeColor: '#FF0000',
//             strokeOpacity: 0.8,
//             strokeWeight: 2,
//             fillColor: '#FF0000',
//             fillOpacity: 0.35,
//             map: map,
//             path: path,
//           });
//           setFreeShape(newShape);
//         } else {
//           alert('Location is outside India. Please mark a location within India.');
//           event.overlay.setMap(null);
//         }
//       }
//     });
//   }, []);

//   const onUnmount = useCallback(() => {
//     setMap(null);
//   }, []);

//   const onLoadAutocomplete = (autocomplete) => {
//     autocompleteRef.current = autocomplete;
//     autocomplete.setBounds(new window.google.maps.LatLngBounds(
//       new window.google.maps.LatLng(6.7471, 68.1471),
//       new window.google.maps.LatLng(35.5087, 97.3954),
//     ));
//     autocomplete.setOptions({ strictBounds: true, types: ['geocode'] });
//   };

//   const onPlaceChanged = () => {
//     const place = autocompleteRef.current.getPlace();

//     if (!place || !place.geometry || !place.geometry.location) {
//       alert(`No details available for input: '${place ? place.name : 'unknown'}'`);
//       console.error('Place details are missing:', place);
//       return;
//     }

//     const location = place.geometry.location;
//     if (!location) {
//       alert('Location data is not available.');
//       console.error('Location data is missing:', place);
//       return;
//     }

//     const boundaryPolygon = new window.google.maps.Polygon({
//       paths: indiaBoundaryCoordinates,
//     });

//     if (!window.google.maps.geometry.poly.containsLocation(location, boundaryPolygon)) {
//       alert('Location is outside India. Please select a location within India.');
//       console.error('Location is outside India:', location);
//       return;
//     }

//     map.setCenter(location);
//     map.setZoom(15);
//   };

//   const refreshMap = () => {
//     if (freeShape) {
//       freeShape.setMap(null);
//     }
//     setFreeShape(null);
//     setSubmitted(false); // Reset submitted state on map refresh
//   };

//   const generateRandomScores = () => {
//     return [
//       { year: '2021', score: Math.floor(Math.random() * 101).toString() },
//       { year: '2022', score: Math.floor(Math.random() * 101).toString() },
//       { year: '2023', score: Math.floor(Math.random() * 101).toString() },
//       { year: '2024', score: Math.floor(Math.random() * 101).toString() },
//     ];
//   };

//   const submitForm = async () => {
//     if (submitted) {
//       alert('Data has already been submitted. Please refresh the page to submit again.');
//       return;
//     }

//     if (!projectName) {
//       alert("Please enter a project name before submitting.");
//       return;
//     }

//     if (!freeShape) {
//       alert('Please draw a shape on the map before submitting.');
//       return;
//     }

//     const path = freeShape.getPath();
//     const coordinates = {};
//     for (let i = 0; i < path.getLength(); i++) {
//       const markerLabel = `Marker #${i + 1}`;
//       const latLng = {
//         lat: path.getAt(i).lat().toFixed(15),
//         lng: path.getAt(i).lng().toFixed(15),
//       };
//       coordinates[markerLabel] = latLng;
//     }

//     const now = new Date();
//     const day = String(now.getDate()).padStart(2, '0');
//     const month = String(now.getMonth() + 1).padStart(2, '0');
//     const year = now.getFullYear();
//     const date = `${day}-${month}-${year}`;

//     let hours = now.getHours();
//     const minutes = String(now.getMinutes()).padStart(2, '0');
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12;
//     hours = hours ? hours : 12;
//     const time = `${hours}:${minutes} ${ampm}`;

//     const ecoscores = generateRandomScores();

//     const payload = {
//       osNumber: uniqueId,
//       coordinates: coordinates,
//       project: projectName,
//       phoneNumber: phoneNumber,
//       ecoscores: ecoscores,
//       date: date,
//       time: time
//     };

//     try {
//       const submitResponse = await fetch('https://z67y80kz0j.execute-api.us-west-1.amazonaws.com/dev/litigation', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'x-api-key': config.apiKey,
//         },
//         body: JSON.stringify(payload),
//       });

//       if (!submitResponse.ok) {
//         alert('There was an error submitting the data.');
//         return;
//       }

//       const responseData = await submitResponse.json();
//       console.log('Response Data:', JSON.stringify(responseData, null, 2));

//       alert('Data submitted successfully!');
//       setSubmitted(true); // Set submitted state to true after successful submission
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       alert('There was an error submitting the data.');
//     }
//   };

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading Maps</div>;
//   }

//   return (
//     <DashboardLayout>
//       <DashboardNavbar absolute isMini />
//       <div className="background-image" style={{ backgroundImage: `url(${Forestbg})` }}>
//         <div className="background-overlay">
//           <div className="content-container">
//             <MDBox pt={6} pb={3}>
//               <MDTypography
//                 variant="h2"
//                 textAlign="center"
//                 color="white"
//                 sx={{
//                   marginBottom: '2rem',
//                 }}
//               >
//                 Project Submission
//               </MDTypography>
//               <div className="form-container">
//                 <MDBox mb={2}>
//                   <StyledTextField
//                     fullWidth
//                     label="Project Name"
//                     value={projectName}
//                     onChange={(e) => setProjectName(e.target.value)}
//                   />
//                 </MDBox>
//                 <MDBox mb={2}>
//                   <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
//                     <StyledTextField
//                       fullWidth
//                       label="Search for a location"
//                       placeholder="Search for a location within India"
//                       variant="outlined"
//                       inputProps={{
//                         style: { color: 'black' },
//                       }}
//                       InputLabelProps={{
//                         style: { color: 'black' },
//                       }}
//                     />
//                   </Autocomplete>
//                 </MDBox>
//                 <div style={mapContainerStyle}>
//                   <GoogleMap
//                     mapContainerStyle={mapContainerStyle}
//                     center={center}
//                     zoom={5}
//                     onLoad={onLoad}
//                     onUnmount={onUnmount}
//                   />
//                 </div>
//                 <MDBox mt={3}>
//                   <Box display="flex" justifyContent="center">
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={submitForm}
//                       disabled={submitted}
//                       sx={{ marginRight: '1rem' }}
//                     >
//                       Submit
//                     </Button>
//                     <Button variant="contained" color="secondary" onClick={refreshMap}>
//                       Refresh Map
//                     </Button>
//                   </Box>
//                 </MDBox>
//                 <MDBox mt={2} textAlign="center">
//                   <MuiLink
//                     component="button"
//                     variant="body2"
//                     onClick={() => navigate('/home')}
//                     color="inherit"
//                     sx={{ marginTop: '1rem' }}
//                   >
//                     Go to Home Page
//                   </MuiLink>
//                 </MDBox>
//               </div>
//             </MDBox>
//           </div>
//         </div>
//       </div>
//     </DashboardLayout>
//   );
// };

// export default Submit;

