import React from 'react';
import './AboutUs.css'; // We'll define styles here
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const AboutUs = () => {
  return (
    <DashboardLayout>
    <div className="about-us-container">
      <div className="content">
        <h1 className="title">About Us</h1>
        <h2 className="subtitle">Who We Are</h2>
        <p className="text">
          We are a team of passionate individuals dedicated to making the world a better place through our innovative solutions. Our mission is to provide excellent services and create value for our customers.
        </p>
      </div>
    </div>
    </DashboardLayout>
  );
};

export default AboutUs;