import React from 'react';
import './carboncredits.css';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Cardsbg1 from '../assets/images/CardsBg1.jpg';
import { useNavigate } from 'react-router-dom';

const CarbonCredits = () => {

  const navigate = useNavigate()
  const phoneNumber = sessionStorage.getItem('phoneNumber');
  if (!phoneNumber) {
    // If not available, navigate to the login page
    navigate('/Login');
    return null; // Return null to prevent rendering the rest of the component
  }

  const handleNavigateToHome = () => {
    navigate('/home');
  };

  return (
    <DashboardLayout backgroundImage={Cardsbg1}> 
      <div className="container">
        <button className="exit-button" onClick={handleNavigateToHome}>Back</button>
        <h1>Carbon Credits</h1>
        <p>
          Carbon credits are a key component of national and international efforts to mitigate the growth in concentrations of Carbonhouse gases (GHGs). One Carbon credit is equal to one tonne of Carbon dioxide, or in some markets, Carbon dioxide equivalent gases. These credits can be traded and sold, and they incentivize companies to reduce their Carbon footprint.
        </p>
        <p>
          The concept of Carbon credits came into existence as a result of the Kyoto Protocol. The goal is to limit the increase of Carbon dioxide levels in the atmosphere. Companies or countries are assigned a certain number of credits, which represent the amount of Carbon dioxide they are allowed to emit. If they need to emit more, they can purchase credits from others who have managed to stay under their limit.
        </p>
        <p>
          Investing in Carbon credits helps promote sustainability by encouraging industries to invest in cleaner technologies and reduce their emissions. The funds generated from selling these credits can be used to finance renewable energy projects, forest conservation, and other environmental initiatives. As the world moves towards a Carboner future, Carbon credits play a crucial role in the global strategy to combat climate change.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default CarbonCredits;