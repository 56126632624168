// import { Grid, InputLabel, Select } from "@material-ui/core";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// // Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// // Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// // Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
// import PieChart from "examples/Charts/PieChart";
// import reportsPieChartData from "./data/reportsPieChartData";
// import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { FormControl, MenuItem } from "@mui/material";
// import getReportsBarChartData from "./data/reportsBarChartData";

// function Dashboard() {
//   const navigate = useNavigate();
//   const [projects, setProjects] = useState([]);
//   const [selectedProject, setSelectedProject] = useState("");
//   const [chartData, setChartData] = useState(getReportsBarChartData([], []));

//   const phoneNumber = '+919741781065';
//   console.log(phoneNumber);

//   useEffect(() => {
//     if (!phoneNumber) {
//       // Navigate if no phone number is found
//       navigate('/Login');
//     } else {
//       const url = `https://z67y80kz0j.execute-api.us-west-1.amazonaws.com/dev/usersubmitted?phone=${encodeURIComponent(
//         phoneNumber
//       )}`;

//       fetch(url)
//         .then((response) => response.json())
//         .then((data) => {
//           const responseBody = JSON.parse(data["body-json"]["body"]);
//           setProjects(responseBody);
//           if (responseBody.length > 0) {
//             setSelectedProject(responseBody[0].osnumber);
//             updateChartData(responseBody[0]);
//           }
//         })
//         .catch((error) => console.error(error));
//     }
//   }, [phoneNumber, navigate]);

//   const handleProjectChange = (event) => {
//     const projectOsNumber = event.target.value;
//     setSelectedProject(projectOsNumber);
//     const selectedProjectData = projects.find(project => project.osnumber === projectOsNumber);
//     updateChartData(selectedProjectData);
//   };

//   const updateChartData = (projectData) => {
//     const labels = projectData.ecoscores.map(score => score.year);
//     const data = projectData.ecoscores.map(score => score.score);
//     setChartData(getReportsBarChartData(labels, data));
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox py={3}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="dark"
//                 icon="weekend"
//                 title="Bookings"
//                 count={281}
//                 percentage={{
//                   color: "success",
//                   amount: "+55%",
//                   label: "than last week",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 icon="leaderboard"
//                 title="Today's Users"
//                 count="2,300"
//                 percentage={{
//                   color: "success",
//                   amount: "+3%",
//                   label: "than last month",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="success"
//                 icon="store"
//                 title="Healthy"
//                 count="+25"
//                 percentage={{
//                   color: "success",
//                   amount: "+1%",
//                   label: "Number Of healthy",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="primary"
//                 icon="person_add"
//                 title="Defected"
//                 count="+91"
//                 percentage={{
//                   color: "success",
//                   amount: "+1%",
//                   label: "Number Of Defected Tomatos",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//         </Grid>
//         <MDBox mt={4.5}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={6} lg={4}>
//               <FormControl fullWidth>
//                 <InputLabel id="project-select-label">Select Project</InputLabel>
//                 <Select
//                   labelId="project-select-label"
//                   value={selectedProject}
//                   onChange={handleProjectChange}
//                 >
//                   {projects.map((project) => (
//                     <MenuItem key={project.osnumber} value={project.osnumber}>
//                       {project.project}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//               <MDBox mb={3}>
//                 <ReportsBarChart
//                   color="info"
//                   title="EcoScores"
//                   description="EcoScores by Year"
//                   date="Updated recently"
//                   chart={chartData}
//                 />
//               </MDBox>
//             </Grid>
//           </Grid>
//         </MDBox>
//         <MDBox>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={6} lg={8}>
//               <Projects />
//             </Grid>
//             <Grid item xs={12} md={6} lg={4}>
//               <OrdersOverview />
//             </Grid>
//           </Grid>
//         </MDBox>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default Dashboard;

// import React, { useState, useEffect } from "react";
// import { Grid, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
// import MDBox from "components/MDBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
// import { useNavigate } from "react-router-dom";

// function Dashboard() {
//   const navigate = useNavigate();
//   const [projects, setProjects] = useState([]);
//   const [selectedProject, setSelectedProject] = useState("");
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: { label: "Ecoscores", data: [] },
//   });

//   // Simulating session storage fetching
//   // const phoneNumber = '+918792292924';
//   const phoneNumber = '+918792292924';
//   console.log(phoneNumber);

//   useEffect(() => {
//     if (!phoneNumber) {
//       // Navigate if no phone number is found
//       navigate('/Login');
//     } else {
//       const url = `https://z67y80kz0j.execute-api.us-west-1.amazonaws.com/dev/usersubmitted?phone=${encodeURIComponent(
//         phoneNumber
//       )}`;

//       fetch(url)
//         .then((response) => response.json())
//         .then((data) => {
//           const responseBody = JSON.parse(data["body-json"]["body"]);
//           setProjects(responseBody);
//           console.log('response:',responseBody);
//           if (responseBody.length > 0) {
//             setSelectedProject(responseBody[0].project);
//             updateChartData(responseBody[0]);
//           }
//         })
//         .catch((error) => console.error(error));
//     }
//   }, [phoneNumber, navigate]);

//   const handleProjectChange = (event) => {
//     const projectOsNumber = event.target.value;
//     setSelectedProject(projectOsNumber);
//     const selectedProjectData = projects.find(project => project.osnumber === projectOsNumber);
//     updateChartData(selectedProjectData);
//   };

//   const updateChartData = (projectData) => {
//     const labels = projectData.ecoscores.map(score => score.year);
//     const data = projectData.ecoscores.map(score => score.score);
//     setChartData({
//       labels,
//       datasets: { label: "Ecoscores", data }
//     });
//   };

//   if (!phoneNumber) {
//     return null; // Prevent rendering if no phone number is found
//   }

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox py={3}>
//         {/* <Grid container spacing={3}>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="dark"
//                 icon="weekend"
//                 title="Bookings"
//                 count={281}
//                 percentage={{
//                   color: "success",
//                   amount: "+55%",
//                   label: "than last week",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 icon="leaderboard"
//                 title="Today's Users"
//                 count="2,300"
//                 percentage={{
//                   color: "success",
//                   amount: "+3%",
//                   label: "than last month",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="success"
//                 icon="store"
//                 title="Healthy"
//                 count="+25"
//                 percentage={{
//                   color: "success",
//                   amount: "+1%",
//                   label: "Number Of healthy",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="primary"
//                 icon="person_add"
//                 title="Defected"
//                 count="+91"
//                 percentage={{
//                   color: "success",
//                   amount: "+1%",
//                   label: "Number Of Defected Tomatoes",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//         </Grid> */}
//         <MDBox mt={4.5}>
//         <Grid container spacing={3}>
//          <Grid item xs={12} md={6} lg={4}>
//          <MDBox mb={3}>
//          <Grid container direction="column" spacing={3}>
//          <Grid item>
//            <FormControl fullWidth>
//              <InputLabel id="project-select-label">Select Project</InputLabel>
//              <Select
//                labelId="project-select-label"
//                id="project-select"
//                value={selectedProject}
//                onChange={handleProjectChange}
//              >
//                {projects.map((project) => (
//                  <MenuItem key={project.osnumber} value={project.osnumber}>
//                    {project.project}
//                  </MenuItem>
//                ))}
//              </Select>
//            </FormControl>
//          </Grid>
//           <Grid item>
//            <ReportsBarChart
//              color="info"
//              title="Ecoscores"
//              description="Ecoscores for selected project"
//              date="Updated recently"
//              chart={chartData}
//            />
//           </Grid>
//         </Grid>
//       </MDBox>
//      </Grid>
//       </Grid>

//         </MDBox>
//         <MDBox>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={6} lg={8}>
//               <Projects />
//             </Grid>
//             <Grid item xs={12} md={6} lg={4}>
//               <OrdersOverview />
//             </Grid>
//           </Grid>
//         </MDBox>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default Dashboard;

import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Select, FormControl, InputLabel, withStyles } from "@material-ui/core";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useNavigate } from "react-router-dom";
import CarbonPricesWidget from "./CarbonPricesWidget"; // Import the CarbonPricesWidget component
import dash from "../../assets/images/dash2.jpg";
import { Typography, Box } from "@mui/material";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

const API_URL = 'https://2tydxkbme3.execute-api.ap-south-1.amazonaws.com/dev/getall';
// Define custom styles
const styles = theme => ({
  select: {
    minWidth: 200, // Adjust the width as needed
    backgroundColor: '#f0f0f0', // Custom background color
  },
  selectBox: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
});

function Dashboard({ classes }) {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectCount] = useState(0); // State to store total number of projects
  const [selectedProject, setSelectedProject] = useState("");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: { label: "Ecoscores", data: [] },
  });
  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        const parsedData = JSON.parse(result['body-json'].body);
        setProjects(parsedData);
        setProjectCount(parsedData.length); // Update project count
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  // Simulating session storage fetching
  const phoneNumber = sessionStorage.getItem('phoneNumber');
 
  useEffect(() => {
    if (!phoneNumber) {
      // Navigate if no phone number is found
      navigate('/Login');
    } else {
      const url = `https://2tydxkbme3.execute-api.ap-south-1.amazonaws.com/dev/usersubmitted?phone=${encodeURIComponent(
        phoneNumber
      )}`;
 
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const responseBody = JSON.parse(data["body-json"]["body"]);
          setProjects(responseBody);
          if (responseBody.length > 0) {
            setSelectedProject(responseBody[0].osnumber);
            updateChartData(responseBody[0]);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [phoneNumber, navigate]);
 
  const handleProjectChange = (event) => {
    const projectOsNumber = event.target.value;
    setSelectedProject(projectOsNumber);
    const selectedProjectData = projects.find(project => project.RequestId === projectOsNumber);
    updateChartData(selectedProjectData);
  };
 
  const updateChartData = (projectData) => {
    const labels = projectData.ecoscores.map(score => score.year);
    const data = projectData.ecoscores.map(score => score.score);
    console.log(projectData)
    setChartData({
      labels,
      datasets: { label: "Ecoscores", data }
    });
  };

  if (!phoneNumber) {
    return null; // Prevent rendering if no phone number is found
  }
 
  return (
    <DashboardLayout backgroundImage={dash}>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid item xs={6} md={6} >
             <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="projects"
                count={projectCount}
                percentage={{
                  color: "success",
                  // amount: "+1%",
                  label: "total number of projects",
                }}
              />
            </MDBox>
        </Grid>
        <Grid container spacing={3}>
          {/* Left Grid Box for ReportsBarChart and CarbonPricesWidget */}
          
          <Grid item xs={12} md={6}>
            <MDBox mb={3}>
              <Grid container direction="column" spacing={6}>
                <Grid item>
                  <Box className={classes.selectBox}>
                    <FormControl fullWidth>
                      <InputLabel id="project-select-label">Select Project</InputLabel>
                      <Select
                        labelId="project-select-label"
                        id="project-select"
                        value={selectedProject}
                        onChange={handleProjectChange}
                        className={classes.select} // Apply custom styles to the Select component
                      >
                        {projects.map((project) => (
                          <MenuItem key={project.RequestId} value={project.RequestId}>
                            {project.project}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <ReportsBarChart
                    color="info"
                    title="Ecoscores"
                    description="Ecoscores for selected project"
                    date="Updated recently"
                    chart={chartData}
                  />
                </Grid>
                <Grid item>
                  {/* Adjusted styling for CarbonPricesWidget */}
                  <div style={{ padding: '16px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                    <Typography>Carbon Prices</Typography>
                    <CarbonPricesWidget />
                  </div>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
 
          {/* Right Grid Box for Projects */}
          <Grid item xs={12} md={6}>
            <Projects />
          </Grid>
        </Grid>
 

      </MDBox>
      
     
      <Footer />
    </DashboardLayout>
  );
}
 
// Apply custom styles using withStyles HOC
export default withStyles(styles)(Dashboard);