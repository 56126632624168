
// import { useState, useEffect } from "react";
// import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import DataTable from "examples/Tables/DataTable";
// import data from "layouts/dashboard/components/Projects/data"; // Import columns configuration

// // API URL
// const API_URL = 'https://z67y80kz0j.execute-api.us-west-1.amazonaws.com/dev/getall';

// function Projects() {
//   const [menu, setMenu] = useState(null);
//   const [projects, setProjects] = useState([]);
//   const { columns } = data(); // Get columns configuration
//   const [projectCount, setProjectCount] = useState(0); // State to store total number of projects

//   const openMenu = ({ currentTarget }) => setMenu(currentTarget);
//   const closeMenu = () => setMenu(null);

//   useEffect(() => {
//     // Fetch data from API
//     const fetchData = async () => {
//       try {
//         const response = await fetch(API_URL);
//         const result = await response.json();
//         const parsedData = JSON.parse(result['body-json'].body);
//         setProjects(parsedData);
//         setProjectCount(parsedData.length); // Update project count
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // Format rows data for the DataTable component
//   const rows = projects.map((project) => ({
//     project: project.project,
//     ecoscores: project.ecoscores.map((score) => `${score.year}: ${score.score}`).join(', '),
//   }));

//   const renderMenu = (
//     <Menu
//       id="simple-menu"
//       anchorEl={menu}
//       anchorOrigin={{
//         vertical: "top",
//         horizontal: "left",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       open={Boolean(menu)}
//       onClose={closeMenu}
//     >
//       <MenuItem onClick={closeMenu}>Action</MenuItem>
//       <MenuItem onClick={closeMenu}>Another action</MenuItem>
//       <MenuItem onClick={closeMenu}>Something else</MenuItem>
//     </Menu>
//   );

//   return (
//     <Card>
//       <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//         <MDBox>
//           <MDTypography variant="h6" gutterBottom>
//             Projects
//           </MDTypography>
//           <MDBox display="flex" alignItems="center" lineHeight={0}>
//             <Icon
//               sx={{
//                 fontWeight: "bold",
//                 color: ({ palette: { info } }) => info.main,
//                 mt: -0.5,
//               }}
//             >
//               done
//             </Icon>
//             <MDTypography variant="button" fontWeight="regular" color="text">
//               &nbsp;<strong>{projectCount} projects</strong> submitted for ecoscore
//             </MDTypography>
//           </MDBox>
//         </MDBox>
//         <MDBox color="text" px={2}>
//           <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
//             more_vert
//           </Icon>
//         </MDBox>
//         {renderMenu}
//       </MDBox>
//       <MDBox>
//         <DataTable
//           table={{ columns, rows }}
//           showTotalEntries={false}
//           isSorted={false}
//           noEndBorder
//           entriesPerPage={true}
//         />
//       </MDBox>
//     </Card>
//   );
// }

// export default Projects;

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import data from "layouts/dashboard/components/Projects/data"; // Import columns configuration

// API URL
const API_URL = 'https://2tydxkbme3.execute-api.ap-south-1.amazonaws.com/dev/getall';

function Projects() {
  const [menu, setMenu] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2021"); // State to store the selected year
  const { columns } = data(); // Get columns configuration
  const [projectCount, setProjectCount] = useState(0); // State to store total number of projects
  const [page, setPage] = useState(0); // State to store current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // State to store rows per page

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        const parsedData = JSON.parse(result['body-json'].body);
        setProjects(parsedData);
        setProjectCount(parsedData.length); // Update project count
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle year selection change
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Format and sort rows data for the DataTable component based on the selected year
  const rows = projects
    .map((project) => {
      const ecoscore = project.ecoscores.find((score) => score.year === selectedYear);
      return {
        project: project.project,
        ecoscore: ecoscore ? ecoscore.score : 0,
      };
    })
    .sort((a, b) => b.ecoscore - a.ecoscore) // Sort by ecoscore in descending order
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); // Paginate rows

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            EcoScore Ranking Table
          </MDTypography>
          {/* <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{projectCount} projects</strong> this month
            </MDTypography>
          </MDBox> */}
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
            </Select>
          </FormControl>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <DataTable
          table={{
            columns: [
              { Header: "Project", accessor: "project" },
              { Header: "Ecoscore", accessor: "ecoscore" }
            ],
            rows,
          }}
          showTotalEntries={true}
          isSorted={true}
          noEndBorder
          entriesPerPage={true}
          pagination={{
            count: projectCount,
            page,
            rowsPerPage,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleRowsPerPageChange,
          }}
        />
      </MDBox>
      
    </Card>
  );
}

export default Projects;