import React, { useEffect, useRef } from "react";

function CarbonPricesWidget() {
  const carbonPricesRef = useRef(null);

  useEffect(() => {
    if (carbonPricesRef.current) {
      const script = document.createElement("script");
      script.src = "https://carboncredits.com/live-carbon-prices/index.js.php";
      script.async = true;
      carbonPricesRef.current.appendChild(script);

      // Adding CSS to hide unwanted elements
      const style = document.createElement("style");
      style.innerHTML = `
        #carbon-prices a {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);

  return (
    <div
      id="carbon-prices"
      ref={carbonPricesRef}
      // style={{
      //   minHeight: '200px',
      //   backgroundColor: 'white',
      //   borderRadius: '8px',
      //   boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      //   padding: '16px',
      //   display: 'flex',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      // }}
    ></div>
  );
}

export default CarbonPricesWidget;
