// context/UserContext.js
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
console.log(phoneNumber)
  return (
    <UserContext.Provider value={{ phoneNumber, setPhoneNumber }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
